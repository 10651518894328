<template>
  <div>
    <b-alert
      v-if="isPreview"
      class="mb-3"
      show
      variant="primary"
    >
      <div class="alert-body">Vous visualisez actuellement agriprogress en tant que {{ coopHveAudit.cooperative.name }}
        <b-badge
          :to="{name: 'admin-coop-list'}"
          :pill="true"
          class="ml-1"
          variant="primary"
        >
          Quitter
        </b-badge></div></b-alert>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { BAlert, BBadge } from "bootstrap-vue";
import { mapState, mapGetters } from "vuex";
import { getUserData } from "@/auth/utils";
import sharedAuditsMixins from "@/mixins/sharedAudits";

export default {
  components: {
    BAlert,
    BBadge
  },
  mixins: [sharedAuditsMixins],
  data() {
    return {
      userData: getUserData()
    };
  },
  computed: {
    ...mapState("cooperative", { coopHveAudit: "hveAudit" }),
    ...mapState("verticalMenu", ["currentPeriod", "currentCooperative", "currentGesCulture", "currentHveCulture"]),
    ...mapState("admin", ["allCooperatives"]),
    ...mapGetters("admin", ["getCooperativeById"]),
    isPreview() {
      return this.userData.type === "Admin" && this.coopHveAudit !== null;
    }
  },
  watch: {
    currentPeriod() {
      this.getAudits();
    },
    currentGesCulture() {
      if (this.currentGesCulture && this.currentCooperative) {
        this.getCooperativeGesAudit();
      }
    }
  },
  created() {
    if (this.userData.type === "Admin") {
      if (this.allCooperatives) {
        this.getAuditsByUrl();
      } else {
        this.$http.ListCooperatives()
          .then(({ data }) => {
            this.$store.commit("admin/SET_ALLCOOPERATIVES", data);
            this.getAuditsByUrl();
          });
      }
    } else {
      this.$store.commit("verticalMenu/SET_HVE_CULTURES", this.userData.cooperative.hve_cultures);
      this.$store.commit("verticalMenu/SET_HVE_CULTURE", this.userData.cooperative.hve_cultures[0]);
      this.$store.commit("verticalMenu/SET_GES_CULTURES", this.userData.cooperative.ges_cultures);
      this.$store.commit("verticalMenu/SET_GES_CULTURE", this.userData.cooperative.ges_cultures[0]);
      this.$store.commit("verticalMenu/SET_COOPERATIVE", this.userData.cooperative);
      this.getAudits();
    }
    this.$store.commit("verticalMenu/SET_COOP_NAVIGATION");
    this.$store.commit("farm/RESET_STATE");
  },
  methods: {
    getAuditsByUrl() {
      const currentCooperative = this.getCooperativeById(this.$route.params.coop_id);
      this.$store.commit("verticalMenu/SET_COOPERATIVE", currentCooperative);
      this.$store.commit("verticalMenu/SET_HVE_CULTURES", currentCooperative.hve_cultures);
      this.$store.commit("verticalMenu/SET_HVE_CULTURE", currentCooperative.hve_cultures[0]);
      this.$store.commit("verticalMenu/SET_GES_CULTURES", currentCooperative.ges_cultures);
      this.$store.commit("verticalMenu/SET_GES_CULTURE", currentCooperative.ges_cultures[0]);
      if (!this.coopHveAudit || this.coopHveAudit.cooperative.id !== this.$route.params.coop_id) {
        this.getAudits();
      }
    }
  }
};
</script>
